import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL, Influencer, WINDOW, InstagramPerformance, InstagramPost, TiktokPerformance } from '@cuepid/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class InfluencerService {

  constructor(@Inject(API_BASE_URL) private apiBaseUrl: string, @Inject(WINDOW) private window: Window,
              private http: HttpClient) {
  }

  get(withFields: string[] | undefined = undefined): Observable<Influencer> {
    const params: any = {};
    if (!!withFields) {
      params['with[]'] = withFields;
    }

    return this.http.get<Influencer>(`${this.apiBaseUrl}/influencer/me`, {
      params,
    }).pipe(
      map(data => plainToClass(Influencer, data)),
    );
  }

  update(key: string, value: any): Observable<Influencer> {
    return this.http.patch<Influencer>(`${this.apiBaseUrl}/influencer/me`, {
      [key]: value,
    }).pipe(
      map(user => plainToClass(Influencer, user)),
    );
  }

  updateIcon(file: Blob): Observable<Influencer> {
    const form = new FormData();
    form.set('icon', file);
    form.set('_method', 'PATCH');
    return this.http.post<Influencer>(`${this.apiBaseUrl}/influencer/me`, form).pipe(
      map(user => plainToClass(Influencer, user)),
    );
  }

  linkInstagram() {
    console.log('linkInstagram');
    this.window.location.href = `${this.apiBaseUrl}/influencer/me/connect/instagram`;
  }
  linkTiktok() {
    console.log('linkTiktok');
    this.window.location.href = `${this.apiBaseUrl}/influencer/me/connect/tiktok`;
  }


  getBankAccount(): Observable<Influencer.BankAccount> {
    return this.http.get<Influencer.BankAccount>(`${this.apiBaseUrl}/influencer/me/bankAccount`);
  }

  storeBankAccount(account: Influencer.BankAccount): Observable<Influencer.BankAccount> {
    return this.http.put<Influencer.BankAccount>(`${this.apiBaseUrl}/influencer/me/bankAccount`, account);
  }

  showInstagramPerformance(from: Date, to: Date): Observable<InstagramPerformance[]> {
    return this.http.get<InstagramPerformance[]>(`${this.apiBaseUrl}/influencer/me/instagram/performance`, {
      params: {
        from: format(from, 'yyyy-MM-dd'),
        to: format(to, 'yyyy-MM-dd'),
      },
    });
  }

  hasUnreadMessage(): Observable<boolean | null> {
    return this.http.get<boolean>(`${this.apiBaseUrl}/influencer/me/message/exist-unread`);
  }

  cancelMembership(reason: string): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}/influencer/me/cancel-membership`, {
      reason,
    });
  }

  getInstagramPosts(): Observable<InstagramPost[]> {
    return this.http.get<InstagramPost[]>(`${this.apiBaseUrl}/influencer/me/instagram/posts`)
  }
  showTikTokPerformance(from: Date, to: Date): Observable<TiktokPerformance[]> {
    return this.http.get<TiktokPerformance[]>(`${this.apiBaseUrl}/influencer/me/tiktok/performance`, {
      params: {
        from: format(from, 'yyyy-MM-dd'),
        to: format(to, 'yyyy-MM-dd'),
      },
    });
  }
}
